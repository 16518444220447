@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Regular"), local("GTWalsheimProRegular"),
    url(./fonts/GTWalsheimProRegular.woff2) format("woff2"),
    url(./fonts/GTWalsheimProRegular.woff) format("woff"),
    url(./fonts/GTWalsheimProRegular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: GT Walsheim Pro;
  src: local("GT Walsheim Pro Bold"), local("GTWalsheimProBold"),
    url(./fonts/GTWalsheimProBold.woff2) format("woff2"),
    url(./fonts/GTWalsheimProBold.woff) format("woff"),
    url(./fonts/GTWalsheimProBold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 10px;
}
:root {
  --primary-color: #00b0f0;
  --secondary-color: #2d2d2d;
  --accent-color: #ffffff;
  --text-color: #808080;
  --background-color: #f7f6f0;
  --custom-background-color: #2c2c2c;
  --heading-color: #5a666a;
  --para-color: #474646;
}

.sunnyHeath_nav .navbar {
  padding-left: 72px;
  height: 98px;
  flex-shrink: 0;
  background: var(--accent-color) !important;
  backdrop-filter: blur(4px);
}

.sunnyHeath_nav .nav-item {
  margin-right: 28px;
}

.sunnyHeath_nav .nav-item a svg {
  margin-bottom: 3px;
}

.sunnyHeath_nav .nav-item .nickname_svg {
  margin-right: 4px;
}

.sunnyHeath_nav .navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0rem;
  padding-left: 0rem;
}
.isHeliosOpen {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .navbar-light .navbar-nav .nav-link */
.sunnyHeath_nav .navbar-nav li a {
  color: var(--text-color) !important;
  font-feature-settings: "salt" on;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

.sunnyHeath_nav .navbar_content ul li .dropdown-menu .dropdown-item {
  color: #5a666a !important;

  font-feature-settings: "salt" on;
  /* Wire/Paragraph/P1/Regular */
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.signupBtn {
  display: inline-flex;
  height: 98px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--primary-color);
  border: none;
  color: var(--accent-color);
  font-feature-settings: "salt" on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

/* start header part start */
.left-image-content img {
  width: 100%;
  align-self: stretch;
  height: 100% !important;
}

.main-right-content-header_box {
  display: flex;
  padding: 80px 98px 80px 72px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  background: var(--background-color);
}
.isheliosOpen {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.heliosIframeExtent {
  position: absolute;
  right: 0;
  background-color: #fff;
  padding-left: 24px;
}
.benefitheliosIframeExtent {
  padding-left: 0px;
}
.main-right-content-header_box h2 {
  color: var(--heading-color);

  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
}

.main-right-content-header_box p {
  color: var(--secondary-color);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.main-right-content-header_box button {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--heading-color);
  border: none;
  color: var(--accent-color);
  text-align: center;
  font-feature-settings: "salt" on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 1.28px;
  text-transform: uppercase;
}

.sign_to_see {
  display: flex;
  padding: 16px 0px;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  background: var(--primary-color);
}

.sign_to_see p {
  color: #fff;
  text-align: center;
  font-feature-settings: "salt" on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 150% */
  margin-bottom: 0;
  text-transform: capitalize;
}

/* start header part start */

.wire-main-section {
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}

.wire-content-div {
  padding: 0px 72px;
}

.wire-main-section h2 {
  color: var(--para-color);
  text-align: center;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;
}

.wire-main-section p {
  color: var(--para-color);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
}

.wire_cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border: none;
}

.card {
  border: none !important;
  border-radius: 0px !important;
}
.home-page-image {
  background-image: url(/public/assets/wire_hero_fruits.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.wire_cards h5 {
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  color: var(--para-color);
}

.wire_cards p {
  color: #000;
  font-feature-settings: "salt" on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-align: start;
}

.wire_cards .card-btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--custom-background-color);
  text-align: center;
  font-feature-settings: "salt" on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
  letter-spacing: 1.28px;
  text-transform: uppercase;
}

.wire_cards .card-btn .link-svg {
  margin-left: 3px;
  margin-bottom: 2px;
}

.wire-content {
  margin-bottom: 40px;
}

.card-main-content {
  margin-top: 24px;
}

/* wire section end */
.app-modal {
  left: 65%;
  /* top: -27px; */
}

.modal-content {
  width: 436.973px !important;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: hidden;
}

/* helios sidebar section start */
.minus-button {
  /* background-image: url(https://app.dev.sunnyrewards.com/999ddc1….png); */
  display: flex;
  padding: 5.532px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5.532px;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 23px;
  right: 19px;
  border-radius: 50%;
  background: var(--System-Colors-Backround-Secondary, #eff0f0);
  border: none;
}
.minus-button svg {
  margin-right: 2px;
}
.helios-popup iframe .icons {
  display: none !important;
}

.main-wrapper {
  transition: margin-right 1s; /* Change margin-left to margin-right */
}

.banner-clicked .main-wrapper {
  margin-right: 0;
}
.wrapper-div {
  height: 100%;
  width: 70%;
  transition: 0.5s;
}
.wrapper-div.closed {
  width: 100%;
}
.wrapper-div.open {
  width: 70%;
}
.sidebar {
  height: 100%;
  width: 29%;
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.5s;
  border-radius: 0px 0px 10px 0px;
  background: #fff;

  box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.benefit_focus_sidebar {
  overflow: scroll;
  height: 100%;
  width: 30%;
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.5s;
  border-radius: 0px 0px 10px 0px;
  background: #fff;
  box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.sidebar.closed,
.benefit_focus_sidebar.closed {
  right: -33%;
}
.benefit_focus_sidebar.open,
.sidebar.open {
  right: 0;
}
/* terms and services */
.main-service {
  padding: 50px 72px;
  font-family: Roboto;
  color: #000;
  font-size: 20px;
}
.main-service .content-service-div h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.main-service .content-service-div section p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: start;
}
.main-service .content-service-div section ul li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: start;
}
.benefitFocus nav .collapse:not(.show) {
  display: block;
}
@media screen and (max-width: 991px) {
  .navbar {
    padding: 0 12px;
  }
  .hideMobileNav {
    display: none;
  }
  .showMobileNav {
    display: block;
  }
  .navbar_content.set {
    position: absolute;
    top: 98px;
    right: 0;
    background: var(--accent-color);
  }
  .benefit_navbar_content.set {
    position: absolute;
    top: 92px;
    right: 38px;
    background: var(--accent-color);
  }
  .isdesktop {
    display: none;
  }
  ul.navbar-nav.mr-auto.responsive-nav {
    margin: 0px !important;
    padding: 3px 12px;
  }

  .signupBtn {
    height: 60px !important;
    width: 100%;
  }

  .set-clox {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .main-header {
    display: flex;
    flex-direction: column-reverse;
  }
  .benefitFocus nav .collapse:not(.show) {
    display: none;
  }
}

@media screen and (max-width: 765px) {
  .main-right-content-header_box {
    padding: 40px 12px;
  }

  .left-image-content img {
    height: 100%;
  }

  .main-header .sign_to_see {
    padding: 16px 12px;
  }

  .wire-content-div {
    padding: 0px 12px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .ismobile {
    display: none;
  }
}
@media screen and (min-width: 992px) and (max-width: 1166px) {
  .main-right-content-header_box {
    padding: 30px 39px 3px 39px;
  }

  .isHelios-item {
    margin-right: 15px !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1473px) {
  .isHelios-item {
    margin-right: 0px !important;
  }
}
@media screen and (min-width: 1474px) and (max-width: 1600px) {
  .isHelios-item {
    margin-right: 10px !important;
  }
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end !important;
}
.benefit-left-side {
  width: 70%;
}

.benefit-right-side {
  width: 29%;
}
.mt-32 {
  margin-top: 32px;
}
.header-btn {
  background: var(--System-Primary-Main, #293a40);
  color: var(--Primary-Contrast, #fff);
  border: none;
  outline: none;
  text-align: center;
  font-family: var(--Primary-Font-Family);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  display: flex;
  gap: 8px;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
}
.benefitFocus {
  border-bottom: 1px solid #e2e2e2;
  background: var(--Surfaces-Backgrounds-Default, #f4f2ed);
}
.benefitFocus li.nav-item {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.benefitFocus .benefits-nav-links {
  margin-left: 22px;
}
.bg-color {
  background: var(--Surfaces-Backgrounds-Default, #f4f2ed);
}
.bg-color nav {
  height: 93px;
}
.eva-back {
  border-radius: 12px;
  border: 1px solid var(--Surfaces-Borders-Paper, #e5e5e5);
  background: var(--Primary-Contrast, #fff);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08),
    0px 1px 10px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px -1px rgba(0, 0, 0, 0.12);
  margin: 0px 20px !important;
}
img.space {
  margin-right: 40px;
  width: 100% !important;
}
.elipse {
  background: #e9e5db;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  position: relative;
}
.alrm-img {
  position: absolute;
  top: -3px;
  right: -2px;
  background-color: #ffcc55;
  border-radius: 50%;
  padding: 3px;
}
.elipse span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 25%;
}
.eva-back {
  padding: 39px;
  justify-content: space-around;
}
.eva-back h4 {
  color: var(--System-Common-Black, #000);
  font-family: var(--Primary-Font-Family);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.elipse-main {
  display: flex;
  width: 154px;
  padding-top: 5px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.benefit-cards {
  display: flex;
  justify-content: center;
}
.benefit-cards h6 {
  text-transform: uppercase;
  text-align: center;
}
.divider {
  border-left: 1px solid #d4d8d9;
}
.text-height {
  height: 36px;
}
.banner {
  padding: 12px 24px 24px 30px;
}
.banner h2 {
  color: #000;
  font-family: "GT Walsheim Pro";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.banner .banner-text p {
  color: #000;
  font-family: "GT Walsheim Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 32px 0px 40px 0px;
}
.banner .get-started {
  display: flex;
  height: 48px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Primary-Main, #293a40);
  color: #fff;
  width: 165px;
  border: none;
  outline: none;
}
.banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.quick-actions {
  border-radius: 0px 0px 12px 12px;
  background: var(--Primary-Main, #293a40);
  padding: 31px 24px 0 24px;
}
.main-card {
  margin-bottom: 32px;
}
.icon-smudge {
  display: flex !important;
  flex-direction: row !important;
  padding: 16px;
  margin-right: 32px;
  margin-bottom: 32px;
  border-radius: 8px !important;
}
.icon-smudge h4 {
  color: #000;
  font-family: var(--Primary-Font-Family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
}
a.elipse-anchor {
  text-decoration: none;
  color: #0049f4;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.benefitFocus .navbar .nav-link {
  color: black !important;
  font-weight: 500;
}
.benefitFocus .navbar .nav-link:hover {
  color: blue !important;
}
.extra-space {
  margin-left: 9.8em;
}
.nav-tabs-bottom {
  border-top: 1px solid #ddd;
}
.nav-tabs-bottom .nav-link {
  border: 1px solid transparent;
  border-top: none;
  color: #007bff; /* Change default tab text color */
}
.nav-tabs-bottom .nav-link.active {
  color: #fff; /* Active tab text color */
  background-color: #007bff; /* Active tab background color */
  border-color: #007bff #007bff #fff; /* Active tab border color */
}
.tab-content {
  border: 1px solid #ddd;
  border-bottom: none;
  padding: 1rem;
}
/* .tab-pane li.nav-item .active {
  border-bottom: 3px solid black;
} */
.benefitAccoutSection .nav-tabs {
  border-bottom: none;
}
.benefitAccoutSection .nav-tabs .nav-link {
  border: none !important;
}
.benefitAccoutSection .nav-tabs .nav-item.show .nav-link,
.benefitAccoutSection .nav-tabs .nav-link.active {
  color: var(--System-Common-Black, #000);
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 3px solid black !important;
  position: relative;
  margin-right: 35px;
}
.benefitAccoutSection .nav-tabs .nav-link.active:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  border-top: 5px solid #000;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}
.benefitAccoutSection .newFeature {
  /* display: flex; */
  padding: 4px;
  /* justify-content: center;
  align-items: center; */
  gap: 8px;
  border-radius: 4px;
  background: var(--System-Secondary-Light, #b2d2fe);
  color: var(--System-Common-Black, #000);
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.benefitAccoutSection .nav-item .nav-link {
  color: var(--System-Common-Black, #000);
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.benefitAccoutSection .benefitAccoutSectionWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.benefitAccoutSection .second-content{
  display: flex;
  align-items: center;
  justify-content: end;
}
.benefitAccoutSection .view {
  color: #000;
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0!important;
}
.benefitAccoutSection .current {
  /* display: flex; */
  padding-left: 24px;
  flex-direction: column;
  align-items: flex-end;
  color: #000;
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-weight: 700;
}
.benefitAccoutSection .current p{
  margin-bottom: 0!important;
  font-size: 17px;
}
.dropdown-toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-toggle .caret {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  margin-top: -9px;
}

.dropdown-toggle .caret svg {
  transition: transform 0.3s ease;
}
.dropdown-toggle::after {
   display: none!important;
}

.dropdown.current .dropdown-toggle[aria-expanded="true"] .caret svg {
  transform: rotate(180deg);
}

.eva-back p {
  color: #000;
  font-family: var(--Primary-Font-Family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.banner-logo {
  display: flex;
  align-items: center;
  height: 93px !important;
  background: var(--2-backgrounds-default, #f4f2ed);
  border-bottom: 1px solid #e2e2e2;
}
.banner-logo svg {
  display: flex;
  margin: 0px 32px 0px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.benefits-home-page .row > * {
  padding-left: 0 !important;
}
.back-to-home {
  display: flex;
  height: 93px;
  padding: 0px 32px 0px 24px;
  align-items: center;
  gap: 8px;
  background: var(--Surfaces-Backgrounds-Default, #f4f2ed);
  cursor: pointer;
}
.back-to-home h5 {
  color: #000;
  text-align: center;
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 6px;
}

.main-helios-ui {
  height: 100% !important;
}
.row > * {
  padding-right: 0 !important;
}
.benefit-card-wrapper .benefit-card{
  display: flex;
  padding: 32px 22px 15px 22px;
  width: 209px;
  flex-direction: column;
  align-items: center;
  /* gap: 16px; */
  align-self: stretch;
  height: 207px;
border-radius: 8px;
border: 1px solid var(--BF-Brand-Grayscale-090-Mercury, #E5E5E5);
background: var(--Primary-Contrast, #FFF);

/* BAAG tile shadow */
box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.10), 0px 1px 3px -1px rgba(0, 0, 0, 0.12);
}
.benefit-card-wrapper .row {
  --bs-gutter-x:0!important;
}
/* .benefit-card-wrapper .benefit-card h6{

} */
/* for mobile */

@media (min-width: 768px) and (max-width: 992px) {
  .wrapper-div.open {
    width: 60%;
  }
  .helios_wrapper {
    width: 40%;
  }
}

@media (max-width: 767.98px) {
  .wrapper-div.open {
    width: 100%;
  }
  .minus-button{
    display: none !important;
  }
  .helios_wrapper {
    width: 100%;
    position: unset;
    height: 800px;
  }
  .full-width-mobile {
    flex-direction: column !important;
  }
}

@media (max-width: 576px) {
  .icon-smudge {
    width: 100%;
    margin-bottom: 32px;
  }
}
@media (min-width: 578px) and (max-width: 992px) {
  .icon-smudge {
    width: auto;
  }
}
@media (min-width: 300px) and (max-width: 992px) {
  .benefitFocus .navbar-nav {
    align-items: flex-start !important;
  }
}

@media (min-width: 992px) and (max-width: 1242px) {
  .benefitFocus .benefits-nav-links {
    margin-left: 5px;
  }
}
